'use strict'
const service = process.env.VUE_APP_service_licenze || ''

exports.creaMovimentoRettificaInventario = async function (base, comparazione, aggiorna) {
  try {
    const data = {
      comparazione
    }
    const response = await fetch(`${service}/utility/inventario/rettifica/${base}${aggiorna ? '?aggiorna=true' : ''}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    return await response.json()
  } catch (err) {
    console.log('creaMovimentoRettificaInventario', err)
  }
}

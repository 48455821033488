/* eslint-disable */
const normalize = (cf) =>	{
  return cf.replace(/\s/g, '').toUpperCase()
}

const format = (cf) => {
  return normalize(cf)
}

const PRIVATE_validate_regular = (cf) => {
  // if( ! /^[0-9A-Z]{16}$/.test(cf) )
  if (!/^[A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1}$/.test(cf)) {
    return 'Invalid characters.'
  }
  var s = 0
  var even_map = 'BAFHJNPRTVCESULDGIMOQKWZYX'
  for (var i = 0; i < 15; i++) {
    var c = cf[i]
    var n = 0
    if ('0' <= c && c <= '9') {
      n = c.charCodeAt(0) - '0'.charCodeAt(0)
    } else {
      n = c.charCodeAt(0) - 'A'.charCodeAt(0)
    }
    if ((i & 1) === 0) {
      n = even_map.charCodeAt(n) - 'A'.charCodeAt(0)
    }
    s += n
  }
  if (s % 26 + 'A'.charCodeAt(0) !== cf.charCodeAt(15)) {
    return 'Invalid checksum.'
  }
  return null
}

const PRIVATE_validate_temporary = (cf) => {
  if (!/^[0-9]{11}$/.test(cf)) {
    return 'Invalid characters.'
  }
  var s = 0
  for (var i = 0; i < 11; i++) {
    var n = cf.charCodeAt(i) - '0'.charCodeAt(0)
    if ((i & 1) === 1) {
      n *= 2
      if (n > 9) {
        n -= 9
      }
    }
    s += n
  }
  if (s % 10 !== 0) {
    return 'Invalid checksum.'
  }
  return null
}

const validate = (cf) => {
  cf = normalize(cf)
  if (cf.length === 0) {
    return 'Empty.'
  } else if (cf.length === 16) {
    return PRIVATE_validate_regular(cf)
  } else if (cf.length === 11) {
    return PRIVATE_validate_temporary(cf)
  } else {
    return 'Invalid length.'
  }
}

export default {
  validate,
  format
}

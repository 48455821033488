<template>
  <v-autocomplete
    v-model="prodotto"
    :loading="loading"
    :items="items"
    :search-input.sync="searchProdotto"
    dense
    no-data-text="Cerca un prodotto per codice o descrizione"
    :label="item.label"
    hide-details
    :rules="rulesValidation"
    item-text="testo"
    item-value="id"
    clearable
    :return-object="true"
    :disabled="disabled"
    @change="onchange"
    >
    <template v-slot:append-outer>
      <v-icon v-if="!item.addForbidden" color="success" @click="onNuovoArticolo" title="Crea un nuovo prodotto">mdi-archive-plus-outline</v-icon>
    </template>
  </v-autocomplete>
</template>

<script>
  import _ from 'lodash'
  import { validazioni } from '@/mixins/validazioni.js'
  import prodotti from '@/services/prodottiService.js'

  export default {
    name: 'prodotto',
    mixins: [
      validazioni
    ],
    props: {
      item: Object,
      disabled: { type: Boolean, default: false },
      updateTrigger: Number,
      base: String
    },
    data (vm) {
      return {
      loading: false,
      items: [],
      debounceSearch: null,
      searchProdotto: '',
      prodotto: '',
      bloccaWatch: false,
      newItem: null
      }
    },
    created() {
      this.debounceSearch = _.debounce(this.searchProdottoDeb, 500)
    },
    async mounted() {
      if (this.item && this.item.value) {
        await this.querySelections(this.item.value)
        this.bloccaWatch = true
        this.prodotto = Object.assign({}, this.items[0])
      }
    },
    watch: {
      async searchProdotto(val) {
        if (this.bloccaWatch) {
          this.bloccaWatch = false
          return
        }
        await this.debounceSearch(val)
      },
      prodottiSelezionati() {
        this.searchProdotto = ''
      },
      updateTrigger() {
        this.$forceUpdate()
      }
/*       initValue(value) {
        console.log('initvalue', value)
        this.searchProdotto = value
      } */
    },
    methods: {
      async searchProdottoDeb(val) {
        // TODO: verificare se quello cercato è già presente prima di richiamare
        // const pr = this.prodottiSelezionati.filter(x => x === val)
        // if (val && pr.length === 0) {
        if (val) {
          await this.querySelections(val)
        }
      },
      async querySelections (val) {
        this.loading = true
        // const data = await prodotti.searchProdottiTC(val)
        const filtri = {
          // testo: val
          codice: val
        }
        const data = await prodotti.getFilteredProducts(this.base, filtri)
        // const cp = data && data.docs.length > 0 ? data.docs : []
        const cp = data && data.total_rows > 0 ? data.rows.map(x => x.doc) : []
        this.items = _.uniqBy(cp, 'ricercaTC').map(x => {
          const v1 = _.get(x, 'varianti.variante1', '')
          const v2 = _.get(x, 'varianti.variante2', '')
          return { 
            testo: `${x.codice} - ${x.descrizione} ${v2 && v2.descrizione} ${v1 && v1.descrizione}`.trim(),
            id: x._id,
            codice: x.codice,
            descrizione: x.descrizione,
            prezzo: x.prezzo && x.prezzo.L1 && x.prezzo.L1.base, // TODO: Prendere listino di default da configurazione (su store..)
            prezzo2: x.prezzo && x.prezzo.L2 && x.prezzo.L2.base,
            acquisto: x.magazzino && x.magazzino.prezzoAcquisto,
            variante1: v1,
            variante2: v2,
            ricercaBarcode: x.ricercaBarcode,
            giacenza: _.get(x, 'magazzino.giacenza', 0)
          }
        })
        this.loading = false
      },
      onchange(value) {
        if (value) {
          this.searchProdotto = ''
          // this.$emit('change', { model: this.item.model, subModel: this.item.subModel, value: this.item.value.id, item: this.item.value })
          this.$emit('change', { model: this.item.model, subModel: this.item.subModel, value: this.prodotto.id, item: value })          
        }
      },
      async onNuovoArticolo() {
        // TODO: Verificare se serve, ho commentato le righe seguenti
        this.newItem = await prodotti.getModello()
       //  this.$store.commit('prodotti/SET_PRODOTTO', this.newItem)
        
       //  this.$store.commit('movimenti/SET_CREA_ARTICOLO')
      }
    }
  }
</script>

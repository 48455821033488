<template>
  <stream-barcode-reader @decode="onDecode" @loaded="onLoaded"></stream-barcode-reader>
</template>

<script>
import { StreamBarcodeReader } from "vue-barcode-reader"
export default {
  components: {
    StreamBarcodeReader
  },
  data() {
    return {}
  },
  methods: {
    onDecode(text) {
      this.$emit('decoded', text)
    },
    onLoaded() {
      this.$emit('loaded')
    }
  }
}
</script>
<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">Modifica / Inserisci valore giacenza</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="formAdd" v-model="formAddFieldsValid" lazy-validation v-on:submit.prevent="salvaGiacenza">
            <v-row>
              <v-col cols="12" sm="6" md="5">
                <v-text-field v-model="valore" label="Valore giacenza" type="number" autofocus @keyup.enter="salvaGiacenza" clearable></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="annulla">Annulla</v-btn>
        <v-btn color="blue darken-1" text @click="salva">Salva</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'editGiacenza',
    props: {
      giacenza: { type: Number, default: 0 },
      show: { type: Boolean, default: false}
    },
    data: (vm) => ({
      dialog: false,
      valore: 0,
      formAddFieldsValid: true
    }),
    watch: {
      show(value) {
        if (value) {
          this.valore = this.giacenza
          this.dialog = true
        }
      }
    },
    methods: {
      salvaGiacenza() {
        // verificare se chiudere la modale o richiedere il "salva" esplicito
      },
      salva() {
        this.$emit('aggiorna-giacenza', this.valore)
        this.dialog = false
      },
      annulla() {
        this.dialog = false
      }
    }
}
</script>
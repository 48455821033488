<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">Modifica / Inserisci i valori dei prezzi di listino</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="formAdd" v-model="formAddFieldsValid" lazy-validation v-on:submit.prevent="salvaPrezzi">
            <v-row>
            <v-col class="mt-3">
              <v-data-table :headers="headersPrezzi" :items="prezzi" dense hide-default-footer disable-sort>
                <template v-slot:[`header.prezzo`]="{ header }">
                  {{header.text}}
                </template>
                <template v-slot:[`item.prezzo`]="{ item }">
                  <v-edit-dialog :return-value.sync="item.prezzo" large persistent cancel-text="Annulla" save-text="Salva" @save="onSavePrezzo(item)">
                    <div>{{ item.prezzo | euro}}</div>
                    <template v-slot:input>
                      <div class="mt-4 text-h6">Prezzo listino</div>
                      <v-text-field v-model="item.prezzo" label="Valore" single-line autofocus :rules="[numerico]"></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
              </v-data-table>
              <h5>* = Listino ivato</h5>
            </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="annulla">Annulla</v-btn>
        <v-btn color="blue darken-1" text @click="salva">Salva</v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="snackbar" :timeout="timeout" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn  color="blue" text v-bind="attrs" @click="snackbar = false">
          OK
        </v-btn>
      </template>
    </v-snackbar>    
  </v-dialog>
</template>

<script>
  import configurazioni from '@/services/configurazioniService.js'
  import prodotti from '@/services/prodottiService.js'

  export default {
    name: 'editGiacenza',
    props: {
      base: String, 
      listini: Object,
      idProdotto: String,
      show: { type: Boolean, default: false}
    },
    data: (vm) => ({
      dialog: false,
      valore: 0,
      formAddFieldsValid: true,
      headersPrezzi: [
        { text: 'LISTINO', value: 'listino', align: 'start' },
        { text: 'PREZZO', value: 'prezzo', align: 'end' }
      ],
      numerico: value => !isNaN(value) || 'Il valore deve essere numerico',
      prezzi: [],
      tabListini: [],
      snackbar: false,
      snackColor: '',
      snackText: '',
      timeout: 4000  
    }),
    async mounted() {
      if (this.base) {
        this.tabListini = await configurazioni.getConfigurazione(this.base, 'listini', 'listini')
      }
    },
    watch: {
      async show(value) {
        if (value) {
          if (this.tabListini.length === 0) {
            this.tabListini = await configurazioni.getConfigurazione(this.base, 'listini', 'listini')
          }
          this.elaboraPrezzi()
          this.dialog = true
        }
      }
    },
    methods: {
      salvaGiacenza() {
        // verificare se chiudere la modale o richiedere il "salva" esplicito
      },
      salva() {
        this.$emit('aggiorna-listini', this.valore)
        this.dialog = false
      },
      annulla() {
        this.dialog = false
      },
      async onSavePrezzo(item) {
        const valore = isNaN(item.prezzo) ? '' : +parseFloat(item.prezzo).toFixed(2)
        await this.aggiornaPrezzo(item, valore)
      },
      async aggiornaPrezzo(item, valore, tipoPrezzo = 'listino') {
        const idProdotto = this.idProdotto
        if (!idProdotto || !valore) {
          this.snackColor = 'red accent-2'
          this.snackText = 'Il valore non è valido'
          this.snackbar = true
          return
        }
        // considero sempre codiceListino perchè la chiave è completa anche se cliente (es _L_codicexxx, _LT_tipoyyyy) o tipoCliente
        
        await prodotti.aggiornaPrezzo(this.base, tipoPrezzo, idProdotto, item.codiceListino, valore)
        await this.elaboraPrezzi()
        this.$emit('aggiorna-listini', { listino: item.codiceListino, valore, codice: idProdotto })
/*         this.snackColor = 'success'
        this.snackText = 'Il prezzo di listino è stato aggiornato'
        this.snackbar = true */
        this.dialog = false
      },            
      async elaboraPrezzi() {
        const prezzi = []
        const listini = Object.keys(this.listini)
        for (let l of listini) {
          const p = this.listini[l].base || 0
          const listino = this.tabListini.find(x => x.codice === l)
          if (listino) {
            const ivato = listino.ivato ? '* ' : ''
            const descrizione =  ivato + (listino.descrizione || listino.codice)
            prezzi.push({ codiceListino: l, listino: descrizione, prezzo: +p.toFixed(2)})
          } else {
            prezzi.push({ listino: l, prezzo: +p.toFixed(2)})
          }
        }
        this.prezzi = prezzi
      }
    }
  }
</script>
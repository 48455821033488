import CodiceFiscale from './elementi/codiceFiscale'
import PartitaIva from './elementi/partitaIva'

export const validazioni = {
  data: (vm) => ({
    rules: {
      required: value => !!value || value === 0 || 'Campo richiesto.',
      counter: value => value && (value.length <= vm.item.maxlength) || `Max ${vm.item.maxlength} caratteri`,
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'E-mail non valida.'
      },
      nospaces: value => (value || '').indexOf(' ') < 0 || 'Gli spazi non sono ammessi',
      partitaiva: value => !value || !PartitaIva.validate(value) || 'Partita iva non valida',
      codicefiscale: value => !value || !CodiceFiscale.validate(value) || 'Codice fiscale non valido',
      numero: value => !isNaN(value) || 'Il valore deve essere numerico',
      setristretto: value => {
        const pattern = /^[a-zA-Z0-9_-]*$/
        return pattern.test(value) || 'Caratteri non validi (0-9a-zA-Z-_)'
      }
    }
  }),
  computed: {
    rulesValidation() {
      let rl = []
      if (this.item.required) {
        rl.push(this.rules.required)
      }
      if (this.item.isEmail) {
        rl.push(this.rules.email)
      }
      if (this.item.maxlength) {
        rl.push(this.rules.counter)
      }
      if (this.item.nospaces) {
        rl.push(this.rules.nospaces)
      }
      if (this.item.partitaiva) {
        rl.push(this.rules.partitaiva)
      }
      if (this.item.codicefiscale) {
        rl.push(this.rules.codicefiscale)
      }
      if (this.item.numerico) {
        rl.push(this.rules.numero)
      }
      if (this.item.setristretto) {
        rl.push(this.rules.setristretto)
      }  
      return rl
    }
  }
}